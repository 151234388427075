<template>
  <div id="page-container" class="main-content-boxed">
    <main id="main-container">
      <div class="hero bg-body-extra-light">
        <div class="hero-inner">
          <div class="content content-full">
            <div class="py-4 text-center">
              <div class="display-1 fw-bold text-danger">
                <i class="fa fa-exclamation-triangle opacity-50 me-1"></i> 404
              </div>
              <h1 class="fw-bold mt-5 mb-2">哎呀..您刚刚发现了一个错误页面..</h1>
              <h2 class="fs-4 fw-medium text-muted mb-5">很抱歉，没有找到您要查找的页面..</h2>
              <a class="btn btn-lg btn-alt-secondary" href="/">
                <i class="fa fa-arrow-left opacity-50 me-1"></i> 返回到主页
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style>
.bgImg {
  background: url("../assets/404.png") no-repeat;
  background-size: 100% 100vh;
}
</style>
